<template>
  <div id="school-standards">
      <b-container class="base-container-x">
        <div v-if="loading === true" class="col-12">
          <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
        </div>
        <div v-if="loading === false" id="banner" class="banner-inner cys">
          <!-- <div class="slideshow_caption">
            <div class="slideshow_inner_caption">
              <div class="slideshow_align_caption">
                <h1 class="section-header-title text-uppercase">{{ $t('EYEP') }}</h1>
                <h3 class="article-content">{{ $t('Exchange Yoga Experience Program (WYA Continuous Yoga Education)') }}</h3>
              </div>
            </div>
          </div> -->
        </div>
        <div v-if="loading === true" class="col-12 mt-4">
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
          <br/>
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
        </div>
        <div v-if="loading === false" class="col-12 mt-4 mb-5 pt-4">
          <div class="d-none d-lg-flex d-xl-flex justify-content-center align-items-center">
            <h2 class="text-center font-weight-bold">{{ $t('Certified Yoga School– CYS') }}</h2>
            <b-img width="100%" class="ml-5" :src="PUBLIC_PATH+'img/certificate/CYS.png'"/>
          </div>
          <div class="d-block d-lg-none d-xl-none">
            <h2 class="text-center font-weight-bold">{{ $t('Certified Yoga School– CYS') }}</h2>
            <div class="d-flex justify-content-center align-items-center">
              <b-img width="100%" class="mt-3" :src="PUBLIC_PATH+'img/certificate/CYS.png'"/>
            </div>
          </div>
          <h5 class="mt-5">{{ $t('Training Hour Standards & Education Levels:') }}</h5>
          <b-table-simple caption-top responsive bordered class="table-default mt-3 levels-table">
            <b-thead class="text-center">
              <b-tr>
              <b-th variant="light" >{{ $t('Additional') }}</b-th>
              <b-th variant="light" >{{ $t('Level 1')}} </b-th>
              <b-th variant="light" >{{ $t('Level 2')}} </b-th>
              <b-th variant="light" >{{ $t('Level 3')}} </b-th>
              <b-th variant="light" >{{ $t('Level 4')}} </b-th>
              </b-tr>
            </b-thead>
            <b-tbody class="text-center">
              <b-tr>
              <b-td @click="scrollToElement('under-50-hours-training-certification')">{{ $t('50 Hours') }}</b-td>
              <b-td rowspan="2" @click="scrollToElement('200-hours-training-certification')">{{ $t('200 Hours') }}</b-td>
              <b-td rowspan="2" @click="scrollToElement('300-hours-training-certification')">{{ $t('300 Hours') }}</b-td>
              <b-td rowspan="2" @click="scrollToElement('500-hours-training-certification')">{{ $t('500 Hours') }}</b-td>
              <b-td rowspan="2" @click="scrollToElement('1000-hours-training-certification')">{{ $t('1000 Hours') }}</b-td>
              </b-tr>
              <b-tr>
              <b-td @click="scrollToElement('100-hours-training-certification')">{{ $t('100 Hours') }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div class="introduction">
            <h3 class="mt-5 mb-3">{{ $t('Introduction:') }}</h3>
            <p>{{ $t('World Yoga Alliance values quality over quantity. This is why our trainings are not only divided into Hours but also into levels. Each level has different syllabus and curriculum standards set by WYA Organization. If you are looking forward to becoming a Certified yoga school, we are waiting for you to join the community. Please select your registration type.') }} {{ $t('If you need assistance, please feel free to') }} <router-link :to="{ name: 'contact' }">{{  $t('contact us')}}.</router-link></p>
            <p>{{ $t('Your success is our responsibility, becoming a Certified Yoga School "CYS", you can have opportunity to provide an International Yoga Course Certification by WYA Organization to your graduates to enhance credibility. Each student who successfully completes any course or yoga teaching training from our Certified Yoga School will receive an official WYA Course Diploma including teacher ID Number, enjoying various benefits provided by WYA Community.') }} {{ $t('Please check our ') }} <router-link :to="{ name: 'EYEP' }">{{ $t('EYEP Standards here.') }}</router-link></p>
            <router-link :to="{ name: 'memberBenefit', hash: '#CYS' }"><p><u>{{ $t('Learn more about the benefits of becoming CYS') }}</u></p></router-link>
            <p class="font-weight-bold text-center">{{ $t('Sample CYT certificate') }}</p>
            <div class="d-none d-sm-flex justify-content-center align-items-end">
                <b-img class="w-25" :src="PUBLIC_PATH + 'img/certificate/CYT_new_sample.jpeg'" />
                <b-img class="w-25 ml-5" :src="PUBLIC_PATH + 'img/certificate/CYT_mini_sample.jpeg'" />
            </div>
            <div class="d-block d-sm-none text-center">
                <b-img class="w-75" :src="PUBLIC_PATH + 'img/certificate/CYT_new_sample.jpeg'" />
                <b-img class="w-75 mt-3" :src="PUBLIC_PATH + 'img/certificate/CYT_mini_sample.jpeg'" />
            </div>
            <p class="text-center mt-5"><i>{{ $t('By obtaining this WYA diploma, your school will be recognized as WYA Certified Yoga School (CYS). The teacher profile of your graduates will be published on our International Yoga Teacher Directory as WYA Certified Yoga Teacher under your school profile. They will also receive several benefits from organization.') }}</i></p>
            <div class="text-center mt-5">
              <b-img class="w-25" :src="PUBLIC_PATH + 'img/certificate/CYT.png'" />
              <h3 class="mt-5 mb-5"><u>{{ $t('CYT Terms of Use:') }}</u></h3>
            </div>
            <p>{{ $t('CYT certification is one of WYA service that allows school members to offer some extra benefits to their graduates through our organization. Please read these Terms of Use (“Terms” “Terms of Service”) carefully. By submitting or using CYT certification you agree to bound by these Terms. If you disagree with any part of these terms then you may not apply the service. Below are the standard guidelines of what you should do and what you should not do when applying CYT certification to your graduates. Failure to comply with any of these terms may result in cancellation of the certification and termination of your membership as CYS may be considered.') }}</p>
            <ol>
              <li>{{ $t('Our annual member can’t apply for CYS Standards. CYS Standards is available only for lifetime members.') }}</li>
              <li>{{ $t('Upon applying for CYT certification, you guarantee that every student has completed all requirements and well-qualified by the end of your training.') }}</li>
              <li>{{ $t('CYS are able to use “WYA Teaching Materials” such as Syllabus and Training manual for their reference. CYS may ask for assistance teaching from WYA organization. However, all materials are considered as WYA copyright, therefore, it cannot be duplicated / shared / or any use for their own commercial profit without permission.') }}</li>
              <li>{{ $t('CYS will be able to publish their student profile (CYT) onto their school profile on WYA Directory homepage') }} <a href="https://www.wyayoga.org/" target="_blank" class="text-primary-dark"><u>www.wyayoga.org</u></a> {{ $t('make sure all of the students’ details are correct.') }}</li>
              <li>{{ $t('CYS must submit CYT (Certified Yoga Teacher) Application profile for every student for the course that has been recognized with WYA. CYS will be responsible for the CYT certification fee. ') }}</li>
              <li>{{ $t('CYS must not be affiliated with other yoga alliances.') }}</li>
              <li>{{ $t('You can apply for CYT certification for the course that have been recognized & certified by WYA only. ') }}</li>
              <li>{{ $t('You must allow WYA official team member to follow-up or investigate the course during your training. (if required)') }}</li>
              <li>{{ $t('Certified Yoga School must follow "EYEP standards" (Exchange Yoga Experience Program) T&C policy Standards.') }}</li>
              <li>{{ $t('You need to Inform all students about CYT benefits.') }}</li>
              <li>{{ $t('If you promote the training claiming that graduates will receive CYT certification upon completion, you must take action for it as per CYT T&C policy Standards.') }}</li>
              <li>{{ $t('Sharing some photos of your training in progress / graduation ceremony via social media is highly recommended and use tag #WYAcertified #wyayoga #WYAcourse') }}</li>
              <li>{{ $t('Maintain your training standards to the highest quality at all times.') }}</li>
              <li>{{ $t('Encourage your students to gain more teaching experience by offering them an internship such as teaching free yoga classes at your school/studio or at any other WYA Certified Yoga Schools.') }}</li>
              <li>{{ $t('You must not apply or sell CYT certificate to those who are not qualified or didn’t actually completed your training.') }}</li>
              <li>{{ $t('You must not register CYT for other schools or any third parties under your school’ name.') }}</li>
              <li>{{ $t('You must not issue, amend, duplicate or reproduce any parts of CYT certificate by yourself.') }}</li>
              <li>{{ $t('In case CYS applied for WYA certificate and later on they would like to stop and issue their own certificate with WYA Logo, their school will no longer recognized as CYS Member but the School Profile status will be changed to RSY– Registered School of Yoga(annual member). CYS Terms and Condition Cancelation Policy Apply.') }}</li>
            </ol>
            <p><b>{{ $t('Please Note') }}:</b> {{ $t('CYT Certification/Diploma is considered as one of our copyrighted intellectual property. WYA reserves the right to randomly check our Certified Yoga Schools activities for compliance of our “CYS Standard Policy”. We may contact you in case we are notified of something that requires our attention and clarification. Please be aware that if any inappropriate actions or violations against out regulations and standards are found, we have all rights to cancel the CYS License, terminate your School membership and remove your data from our registry with no refund.') }}</p>
            <div class="text-center mt-5">
              <h3>{{ $t('Here is our International Education Standards for Certified Yoga Courses Providers:') }}</h3>
              <p>-: {{ $t('Any Subject') }} :-</p>
            </div>
            <ul>
              <li @click="scrollToElement('under-50-hours-training-certification')"><strong>{{ $t('Under 50 Hours Training Certification') }}:</strong></li>
            </ul>
            <p>{{ $t('This certification type is good for starters who love working with a small community and would like to provide high quality courses with WYA.') }} <a href="javascript:void(null)" @click="scrollToElement('under-50-hours-training-certification')">{{ $t('Read More...') }}</a></p>
            <ul>
              <li @click="scrollToElement('100-hours-training-certification')"><strong>{{ $t('100 Hours Training Certification') }}:</strong></li>
            </ul>
            <p>{{ $t('This is a primary beginner level yoga training for everyone who wants to learn and practice yoga from the foundation.') }} <a href="javascript:void(null)" @click="scrollToElement('100-hours-training-certification')">{{ $t('Read More...') }}</a></p>
            <ul>
              <li @click="scrollToElement('200-hours-training-certification')"><strong>{{ $t('200 Hours Training Certification') }}:</strong></li>
            </ul>
            <p>{{ $t('You can upgrade your school license under this standard if you are providing yoga teacher training at Level 1.') }} <a href="javascript:void(null)" @click="scrollToElement('200-hours-training-certification')">{{ $t('Read More...') }}</a></p>
            <ul>
              <li @click="scrollToElement('300-hours-training-certification')"><strong>{{ $t('300 Hours Training Certification') }}:</strong></li>
            </ul>
            <p>{{ $t('Upon successful register as 200-hours yoga school license you can upgrade your school license under this standard if you are providing yoga teacher training as Level 2.') }} <a href="javascript:void(null)" @click="scrollToElement('300-hours-training-certification')">{{ $t('Read More...') }}</a></p>
            <ul>
              <li @click="scrollToElement('500-hours-training-certification')"><strong>{{ $t('500 Hours Training Certification') }}:</strong></li>
            </ul>
            <p>{{ $t('Upon successful register as 200-hours / 300-hours yoga school license you can upgrade your school license under this standard if you are providing yoga teacher training as Level 3.') }}  <a href="javascript:void(null)" @click="scrollToElement('500-hours-training-certification')">{{ $t('Read More...') }}</a></p>
            <ul>
              <li @click="scrollToElement('1000-hours-training-certification')"><strong>{{ $t('1000 Hours Training Certification') }}:</strong></li>
            </ul>
            <p>{{ $t('Upon successful register as 500-hours yoga school license you can upgrade your school license under this standard if you are providing yoga teacher training as Level 4.') }} <a href="javascript:void(null)" @click="scrollToElement('1000-hours-training-certification')">{{ $t('Read More...') }}</a></p>
          </div>
          <div id="under-50-hours-training-certification" class="mt-5">
            <h4 class="font-weight-bold text-center mb-5">{{ $t('Under 50 Hours training level') }}:</h4>
            <p>{{ $t('This type of teacher training is an additional Certification Course provided by WYA Certified Schools worldwide. This course is for all those who wish to follow exclusive teacher training in any subject.') }}</p>
            <p>{{ $t('WYA 50 Hours teacher training framework certification carries out the highest quality and helps you to make your course invaluable and recognized worldwide by the yoga and health community. We set all of the following health and safety standards in order to provide the best experience for our students.') }}</p>
            <p>{{ $t('Please note, this is a very specific course intended for all those who have already completed any of 200 Hours Yoga Teacher Training by any WYA Certified school, and would like to become a specialist in yoga education.') }}</p>
            <p><strong><u>{{ $t('Eligibility to Sign up') }}</u>:</strong> {{ $t('Everyone.') }}</p>
            <p>{{ $t('Duration: Total 5 – 6 days regular training. However, it depends on the course timetable.') }}</p>
            <p><strong>{{ $t('Training Standards for Certified 50 Hours Course Providers:') }}</strong></p>
            <ul><li>{{ $t('Practical Techniques (Total 25 hours with 21 contact hours)') }}</li></ul>
            <p>{{ $t('This training includes the techniques of physical and mental level:') }}</p>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                <b-td >{{ $t('AsanaTechniques') }}</b-td>
                <b-td >{{ $t('13 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                <b-td >{{ $t('Teaching Methodology') }}</b-td>
                <b-td >{{ $t('8 Hours') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Theoretical (total 25 hours with 20 contact hours)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                <b-td >{{ $t('Adjustment and assistant techniques') }}</b-td>
                <b-td >{{ $t('4 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                <b-td >{{ $t('Theory ') }}</b-td>
                <b-td >{{ $t('16 Hours') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Homework (total 9 non-contact hours)') }}</li></ul>
            <p>{{ $t('Homework includes a personal practice of yoga theoretical knowledge and practical techniques, journaling, and specific essay assignments including communication skills.') }}</p>
            <p>{{ $t('If you have any questions about the training standards, please feel free to') }}  <router-link :to="{ name:'contact' }">{{ $t('contact us.') }}</router-link></p>
            <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button></div>
          </div>
          <div id="100-hours-training-certification" class="mt-5">
            <h4 class="font-weight-bold text-center mb-5">{{ $t('100 Hours training level') }}:</h4>
            <p>{{ $t('WYA 100 Hours yoga teacher training framework certification carries out the highest quality and helps you to make your course invaluable and recognized worldwide by the yoga community. We set all of the following health and safety standards in order to provide the best experience for our students.') }}</p>
            <p>{{ $t('100 Hours Yoga Teacher Training is a standard training set forth by the World Yoga Alliance for all certified Yoga Schools. This training is based on 200 Hours yoga teacher training, but is conveniently divided into two sections, each of 100 hours. This is a primary beginner level yoga training for everyone who wants to learn and practice yoga. It’s very important that your training is done and completed with the same yoga school/teacher, so you can receive a diploma after each completed 100 Hours training section. When you fully complete your 200 Hours Teacher Training Course (2 separate courses), you will be received World Yoga Alliance Certified Yoga Teacher Diploma.') }}</p>
            <p><strong><u>{{ $t('Eligibility to Sign up') }}</u>:</strong> {{ $t('Everyone.') }}</p>
            <p>{{ $t('Duration: Total 10 – 11 days regular training. However, it depends on the course timetable.') }}</p>
            <p><strong>{{ $t('Training Standards for Certified 100 Hours Course Providers:') }}</strong></p>
            <ul><li>{{ $t('Practical Techniques (total 58 hours with 46 contact hours)') }}</li></ul>
            <p>{{ $t('This training including the techniques of physical and mental level:') }}</p>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                <b-th class="w-25" variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                <b-td >{{ $t('AsanaTechniques') }}</b-td>
                <b-td >{{ $t('30 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                <b-td >{{ $t('Teaching Methodology') }}</b-td>
                <b-td >{{ $t('10 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                <b-td >{{ $t('Practical Teaching & Workshop') }}</b-td>
                <b-td >{{ $t('6 Hours') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Theoretical (total 42 hours with 34 contact hours)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                <b-td >{{ $t('Mobility & Strength Techniques') }}</b-td>
                <b-td >{{ $t('14 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                <b-td >{{ $t('Theory of Practical') }}</b-td>
                <b-td >{{ $t('12 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                <b-td >{{ $t('Anatomy and Physiology') }}</b-td>
                <b-td >{{ $t('8 Hours') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Homework (total 20 non-contact hours)') }}</li></ul>
            <p>{{ $t('Homework includes a personal practice of yoga theoretical knowledge and practical techniques, journaling, and specific essay assignments including communication skills.') }}</p>
            <p>{{ $t('If you have any questions about the training standards, please feel free to') }}  <router-link :to="{ name:'contact' }">{{ $t('contact us.') }}</router-link></p>
            <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button></div>
          </div>
          <div id="200-hours-training-certification" class="mt-5">
            <h4 class="font-weight-bold text-center mb-5">{{ $t('200 Hours training level 1') }}:</h4>
            <p>{{ $t('WYA 200 Hours yoga teacher training framework certification carries out the highest quality and helps you to make your course invaluable and recognized worldwide by the yoga community. We set all of the following health and safety standards in order to provide the best experience for our students.') }}</p>
            <p>{{ $t('200 Hours Yoga Teacher Training is a standard Level 1 yoga training course designed for everyone, regardless of age and previous yoga experience, who would like to learn and master the basics and principles of yoga practice. This training standard is set forth by the World Yoga Alliance Organization to provide most valuable yoga knowledge, principles, and ethical guidelines to all yoga practitioners around the world. We present this standard to all our CYS members to follow and structure their courses, making sure that WYA education quality is well maintained across the world.') }}</p>
            <p>{{ $t('The structure of the 200 Hours YTTC allows for all those who have never practiced yoga before to easily follow the teachings, and for those who already have some experience to deepen and broaden their knowledge. We believe in inner balance and emotional harmony, and our goal is to give you a transformational experience, regardless of who you are, that you can then apply to your yoga practice and teachings.') }}</p>
            <p>{{ $t('WYA Teacher Training Course Standards include both theoretical and practical yoga techniques, as well as homework to make sure you are ready to become a professional yoga teacher or yoga school.') }} {{ $t('Please check our ') }} <router-link :to="{ name: 'EYEP' }">{{ $t('EYEP Standards here.') }}</router-link></p>
            <p><strong><u>{{ $t('Eligibility to Sign up') }}</u>:</strong> {{ $t('Everyone.') }}</p>
            <p>{{ $t('Duration: Total 21 – 30 days regular training. However, it depends on the course timetable.') }}</p>
            <p><strong>{{ $t('Training Standards for Certified 200 Hours Course Providers:') }}</strong></p>
            <ul><li>{{ $t('Practical Techniques (total 119 hours with 94 contact hours)') }}</li></ul>
            <p>{{ $t('This training includes the techniques of physical and mental level:') }}</p>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('Yoga-Asana Techniques') }}</b-td>
                  <b-td >{{ $t('60 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Teaching Methodology') }}</b-td>
                  <b-td >{{ $t('17 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Business Plan - Basic knowledge') }}</b-td>
                  <b-td >{{ $t('6 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Group Meeting / Teaching Practice') }}</b-td>
                  <b-td >{{ $t('6 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Teaching practical & Workshop: Student teaching as well as observing and assisting in yoga classes taught by others. Hours may be a combination of supervised and unsupervised.') }}</b-td>
                  <b-td >{{ $t('5 Hours') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Theoretical (total 81 hours with 56 contact hours)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-tbody>
                <b-tr>
                  <b-td class="w-75">{{ $t('Theory and Introductionof Yoga Style') }}</b-td>
                  <b-td >{{ $t('25 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Introduction and how to use other useful equipment.') }}</b-td>
                  <b-td >{{ $t('15 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Anatomy and Physiology') }}</b-td>
                  <b-td >{{ $t('16 Hours ') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Homework (total 50 non-contact hours)') }}</li></ul>
            <p>{{ $t('Homework includes a personal practice of yoga theoretical knowledge and practical techniques, journaling, and specific essay assignments including communication skills.') }}</p>
            <p>{{ $t('If you have any questions about the training standards, please feel free to') }}  <router-link :to="{ name:'contact' }">{{ $t('contact us.') }}</router-link></p>
            <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button></div>
          </div>
           <div id="300-hours-training-certification" class="mt-5">
            <h4 class="font-weight-bold text-center mb-5">{{ $t('300 Hours training level 2') }}:</h4>
            <p>{{ $t('WYA 300 Hours yoga teacher training framework certification carries out the highest quality and helps you to make your course invaluable and recognized worldwide by the yoga community. We set all of the following health and safety standards in order to provide the best experience for our students.') }}</p>
            <p>{{ $t('This 300 Hours Yoga Teacher Training is a Level 2 that follows further the syllabus and structure of the 200 Hours level 1 yoga TTC. This level is designed for all those who have already completed the 200 Hours Yoga Teacher Training and would like to deepen and broaden their knowledge, becoming professional yoga teachers with the ability to become WYA Certified Yoga Trainers. If applicant haven’t graduated from a WYA 200 Hours YTTC School, need to pass the orientation test to ensure that applicant have sufficient basic skills and knowledge to undertake further advanced course and continue the program. We recommend all WYA certified schools to require applicants of the 200 Hour YTTC to pass the orientation test, specifically if the applicant has not previously completed a 200 Hour YTTC through WYA Certified Yoga School.') }}</p>
            <p>{{ $t('All those who already have the 200 Hours level one YTT WYA Certificate with valid teacher ID, upon completion, will be able to receive a 500 Hours Yoga Teacher Training Diploma with World Yoga Alliance. If student had done 200-hour course with non WYA Certified Yoga School, then our school can provide only 300-hour Yoga Teacher Diploma.') }}</p>
            <p>{{ $t('Graduates will enjoy various benefits and continuous support from the organization, becoming certified yoga teachers with World Yoga Alliance, having your own public profile page listed on our official website, where you can advertise your classes, courses or events.') }} {{ $t('Please check our ') }} <router-link :to="{ name: 'EYEP' }">{{ $t('EYEP Standards here.') }}</router-link></p>
            <p><strong><u>{{ $t('Eligibility to Sign up') }}</u>:</strong> {{ $t('After passed the 200 Hour Level 1.') }}</p>
            <p>{{ $t('Duration: Total 30 – 45 days regular training. However, it depends on the course timetable.') }}</p>
            <p><strong>{{ $t(' Training Standards for Certified 300 Hours Course Providers:') }}</strong></p>
            <ul><li>{{ $t('Practical (total 172 hours with 138 contact hours)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('Yoga-Asana Techniques') }}</b-td>
                  <b-td >{{ $t('51 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Teaching Methodology') }}</b-td>
                  <b-td >{{ $t('47 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Business Plan') }}</b-td>
                  <b-td >{{ $t('13 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Group Meeting / Practice: Technique to become a Public Speaker') }}</b-td>
                  <b-td >{{ $t('11 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Practical Teaching & Workshop') }}</b-td>
                  <b-td >{{ $t('16 Hour') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Theoretical (total 128 hour with 87 contact hour)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('Theory') }}</b-td>
                  <b-td >{{ $t('33 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Introduction and how to use other useful equipment.') }}</b-td>
                  <b-td >{{ $t('28 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Anatomy and Physiology') }}</b-td>
                  <b-td >{{ $t('26 Hours') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Homework (total 75 non-contact hours)') }}</li></ul>
            <p>{{ $t('Homework includes a personal practice of yoga theoretical knowledge and practical techniques, journaling, and specific essay assignments including communication skills.') }}</p>
            <p>{{ $t('If you have any questions about the training standards, please feel free to') }}  <router-link :to="{ name:'contact' }">{{ $t('contact us.') }}</router-link></p>
            <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button></div>
          </div>
          <div id="500-hours-training-certification" class="mt-5">
            <h4 class="font-weight-bold text-center mb-5">{{ $t('500 Hours training level 3') }}:</h4>
            <p>{{ $t('WYA 500 Hours yoga teacher training framework certification carries out the highest quality and helps you to make your course invaluable and recognized worldwide by the yoga community. We set all of the following health and safety standards in order to provide the best experience for our students.') }}</p>
            <p>{{ $t('This 500 Hours Yoga Teacher Training is a Level 3 that follows further the syllabus and structure of the 300 Hours YTT level 2. This level is designed for all those who have already completed the 300 Hours Yoga Teacher Training and would like to deepen and broaden their knowledge, becoming professional yoga teachers with the ability to become WYA Certified Yoga Trainers. If applicant haven’t graduated from a WYA 300 Hours YTTC Certified Yoga School, need to pass the orientation test to ensure that applicant have sufficient basic skills and knowledge to undertake further advanced course and continue the program. We recommend all WYA certified schools to require applicants of the 300 Hour YTTC to pass the orientation test, specifically if the applicant has not previously completed a 300 Hour YTTC through WYA Certified Yoga Schools.') }}</p>
            <p>{{ $t('All those who already have the 300 Hours level two YTT WYA Certificate with valid teacher ID, upon completion, they will be able to receive a 800 Hours Yoga Teacher Training Diploma by World Yoga Alliance CYS. If student had done 300-hour course with non WYA Certified Yoga School, then our school can provide only 500-hour Yoga Teacher Diploma.') }}</p>
            <p>{{ $t('Graduates will enjoy various benefits and continuous support from the organization, becoming certified yoga teachers with World Yoga Alliance, having your own public profile page listed on our official website, where you can advertise your classes, courses or events.') }} {{ $t('Please check our ') }} <router-link :to="{ name: 'EYEP' }">{{ $t('EYEP Standards here.') }}</router-link></p>
            <p><strong><u>{{ $t('Eligibility to Sign up') }}</u>:</strong> {{ $t('After passed the 300 Hour Level 2.') }}</p>
            <p>{{ $t('Duration: Total 60 – 80 days regular training. However, it depends on the course timetable.') }}</p>
            <p><strong>{{ $t('Training Standards for Certified 500 Hours Course Providers:') }}</strong></p>
            <ul><li>{{ $t('Practical (total 283 hour with 217 contact hour) ') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('Yoga-Asana Techniques') }}</b-td>
                  <b-td >{{ $t('108 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Teaching Methodology') }}</b-td>
                  <b-td >{{ $t('52 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Business Plan') }}</b-td>
                  <b-td >{{ $t('30 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Group Meeting / Practice: Technique to become a Public Speaker') }}</b-td>
                  <b-td >{{ $t('14 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Practical Teaching & Workshop') }}</b-td>
                  <b-td >{{ $t('13 Hour') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Theoretical (total 217 hour with 151 contact hour)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('Theory') }}</b-td>
                  <b-td >{{ $t('73 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Introduction and how to use other useful equipment.') }}</b-td>
                  <b-td >{{ $t('40 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Anatomy and Physiology') }}</b-td>
                  <b-td >{{ $t('38 Hour') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Homework (total 132 non-contact hours)') }}</li></ul>
            <p>{{ $t('Homework includes a personal practice of yoga theoretical knowledge and practical techniques, journaling, and specific essay assignments including communication skills.') }}</p>
            <p>{{ $t('If you have any questions about the training standards, please feel free to') }}  <router-link :to="{ name:'contact' }">{{ $t('contact us.') }}</router-link></p>
            <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button></div>
          </div>
          <div id="1000-hours-training-certification" class="mt-5">
            <h4 class="font-weight-bold text-center mb-5">{{ $t('1,000 Hours training level 4') }}:</h4>
            <p>{{ $t('WYA 1,000 Hours yoga teacher training framework certification carries out the highest quality and helps you to make your course invaluable and recognized worldwide by the yoga community. We set all of the following health and safety standards in order to provide the best experience for our students.') }}</p>
            <p>{{ $t('This 1,000 Hours Yoga Teacher Training is a Level 4 that follows further the syllabus and structure of the 500 Hours YTT as level 3. This level is designed for all those who have already completed the level 3 Yoga Teacher Training and would like to deepen and broaden their knowledge, becoming professional yoga teachers with the ability to become WYA Certified Yoga Trainers.') }}</p>
            <p>{{ $t('If applicant haven’t graduated from a WYA Level 3 Certified Yoga School, need to pass the orientation test to ensure that applicant have sufficient basic skills and knowledge to undertake further advanced course and continue the program. We recommend all WYA certified schools to require applicants of the YTTC to pass the orientation test, specifically if the applicant has not previously completed a level 3 YTTC through WYA Certified Yoga Schools.') }}</p>
            <p>{{ $t('All those who already have the Level 3 YTT WYA Certificate with valid teacher ID, upon completion, they will able to receive a Yoga Teacher Training Diploma with combined hours such as;') }}</p>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('If you have done WYA 500-hour level 3 will received 1,500 Hour Teacher Training Diploma.') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('If you had done 500-hour level 3 course with non WYA School, then you will get only 1,000-hour Yoga Teacher Diploma.') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <p>{{ $t('Graduates will enjoy various benefits and continuous support from the organization, becoming certified yoga teachers with World Yoga Alliance, having your own public profile page listed on our official website, where you can advertise your classes, courses or events.') }} {{ $t('Please check our ') }} <router-link :to="{ name: 'EYEP' }">{{ $t('EYEP Standards here.') }}</router-link></p>
            <p><strong><u>{{ $t('Eligibility to Sign up') }}</u>:</strong> {{ $t('After passed the Level 3 as 500 Hours or 600 Hours Yoga Teacher Training.') }}</p>
            <p>{{ $t('Duration: Total 140 – 365 days regular training. However, it depends on the course timetable.') }}</p>
            <p><strong>{{ $t(' Training Standards for Certified 1,000 Hours Course Providers:') }}</strong></p>
            <ul><li>{{ $t('Practical (total 533 hour with 408 contact hour)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('Yoga-Asana Techniques') }}</b-td>
                  <b-td >{{ $t('148 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Teaching Methodology') }}</b-td>
                  <b-td >{{ $t('92 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Business Plan') }}</b-td>
                  <b-td >{{ $t('70 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Group Meeting / Practice: Technique to become a Public Speaker') }}</b-td>
                  <b-td >{{ $t('54 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Practical Teaching & Workshop') }}</b-td>
                  <b-td >{{ $t('44 Hour') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Theoretical (total 467 hour with 342 contact hour)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('Theory') }}</b-td>
                  <b-td >{{ $t('138 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Introduction and how to use other useful equipment.') }}</b-td>
                  <b-td >{{ $t('103 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Anatomy and Physiology') }}</b-td>
                  <b-td >{{ $t('101 Hour') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Homework (total 250 non-contact hours)') }}</li></ul>
            <p>{{ $t('Homework includes a personal practice of yoga theoretical knowledge and practical techniques, journaling, and specific essay assignments including communication skills.') }}</p>
            <p>{{ $t('If you have any questions about the training standards, please feel free to') }}  <router-link :to="{ name:'contact' }">{{ $t('contact us.') }}</router-link></p>
            <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button></div>
          </div>
        </div>
      </b-container>
  </div>
</template>

<script>
export default {
  name: 'ChildrenYogaSchool',
  title: ' | Children\'s Yoga School',
  data () {
    return {
      loading: false
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    scrollToElement (id) {
      const banner = document.getElementById('banner').offsetHeight
      const element = document.getElementById(id)
      const elementOffset = element.offsetTop + banner - 105
      window.scroll({ top: elementOffset, behavior: 'smooth' })
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
 #school-standards {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .levels-table td:hover,
  .introduction ul li:hover,
  .introduction strong:hover {
    cursor:pointer;
  }
</style>
